import type { InstanceTypeOf } from '@/type-utils';
import type { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { ResourceNotFoundError } = errorFactory('ResourceNotFoundError', {
  mapsToHTTPError: 'NotFoundError'
} as IHTTPErrorMetaData);

/** Generic Error to use when an expected resource is not found. */
export type ResourceNotFoundError = InstanceTypeOf<
  typeof ResourceNotFoundError
>;
