import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '../error-utils';
import { EnvironmentError } from './EnvironmentError';

export const { ServerCodeInClientError } = errorFactory(
  'ServerCodeInClientError',
  EnvironmentError
);

/**
 * Error to use to indicate that there was an attempt to execute server-side
 * only code in the browser.
 */
export type ServerCodeInClientError = InstanceTypeOf<
  typeof ServerCodeInClientError
>;
