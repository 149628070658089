import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { InvalidArgumentError } = errorFactory('InvalidArgumentError', {
  mapsToHTTPError: 'BadRequestError'
} as IHTTPErrorMetaData);

/** Generic Error to use when an argument to a function or method is not considered valid. */
export type InvalidArgumentError = InstanceTypeOf<typeof InvalidArgumentError>;
