import { UnreachableError } from './errors';

/**
 * Gets the filename, line, and column for the caller function as a string. This
 * can be helpful in generating messages useful for debugging.
 * @returns The filename, line, and column for the caller function as a string.
 * @example ```ts
 * const testFunc = (): void => {
 *   const callerLocation = getCallerLocation(); // 'testFunc2...'
 * };
 *
 * const testFunc2 = (): void => {
 *   testFunc();
 * };
 * ```
 */
export const getCallerLocation = (): string => {
  // Instantiate an error object to obtain a stack trace from.
  const err = new Error();

  // Line 1 is "Error: ",
  // line 2 is this function in the callstack,
  // line 3 is the function calling this function in the callstack
  // line 4 is the actual caller we're attempting to obtain in the callstack.
  const callerLine = err.stack!.split('\n')[3];

  // Return the line of the stack trace with the leading "    at " stripped.
  return callerLine.replace(/^\s*at\s/, '');
};

/**
 * Gets the callstack array from V8. Should only be used server-side as this
 * code is only supported in browsers whose JavaScript Engine is V8.
 * @returns The native callstack array for the current function scope.
 * @throws When the code is being used client side. Wrap this function call
 * in a check for the server, if need be.
 * @see https://v8.dev/docs/stack-trace-api
 */
export const getCallStack = (): Array<NodeJS.CallSite> => {
  if (typeof window !== 'undefined')
    throw new Error(
      `\`getCallStack\` should not be called outside of Node JS contexts.`
    );
  // Save the original native implementation.
  const originalPrepareStackTrace = Error.prepareStackTrace;
  const originalStackTraceLimit = Error.stackTraceLimit;

  Error.stackTraceLimit = Number.MAX_SAFE_INTEGER;

  // Create an implementation which returns the callstack.
  Error.prepareStackTrace = (error, stack) => stack;

  // Remove this function itself from the callstack.
  const stack = new Error().stack?.slice(
    1
  ) as unknown as Array<NodeJS.CallSite>;

  // Restore the original native implementation.
  if (originalPrepareStackTrace) {
    Error.prepareStackTrace = originalPrepareStackTrace;
  }
  Error.stackTraceLimit = originalStackTraceLimit;

  return stack;
};

/** Represents the possible overloads of {@link pipe}. */
interface IPipeFunction {
  /**
   * `pipe` takes multiple functions and passes the return value of one function and passes
   * it as the argument(s) to the next function. It will return the result of the last
   * function call. This is useful for calling a series of functions as "steps". The only
   * caveat to using this is that the arguments and return value of the function must be of
   * the same type. For instance, if a function accepts a number as it's only argument, it
   * must return a number.
   * @param Args - A type representing the types of arguments the functions accept.
   * @param ReturnValue - A type representing the return values that the functions return.
   * @param FunctionSignature - A type representing the signature of functions in the pipeline.
   * @param args - Initial arguments to pass to the first function call.
   * @param funcs - A list of functions to call in order, with each function passing its
   * return value into the next function's arguments.
   * @returns The result of the last function call.
   * @example ```ts
   * pipe([req, resp], createSession, createCSRFToken); // Output [req, resp]
   * ```
   */
  <
    Args extends Array<unknown>,
    ReturnValue extends Args,
    FunctionSignature extends (...args: Args) => Args
  >(
    args: [...Args],
    ...funcs: Array<FunctionSignature>
  ): ReturnValue;

  /**
   * `pipe` takes multiple functions and passes the return value of one function and passes
   * it as the argument(s) to the next function. It will return the result of the last
   * function call. This is useful for calling a series of functions as "steps". The only
   * caveat to using this is that the arguments and return value of the function must be of
   * the same type. For instance, if the function accepts two strings as arguments, the function
   * must return an array of two strings.
   * @param Args - A type representing the type of the argument that the functions accept.
   * @param ReturnValue - A type representing the return values that the functions return.
   * @param FunctionSignature - A type representing the signature of functions in the pipeline.
   * @param arg - The initial argument to pass to the first function call.
   * @param funcs - A list of functions to call in order, with each function passing its
   * return value into the next function's arguments.
   * @returns The result of the last function call.
   * @example ```ts
   * pipe(1, addOne, minusTwo); // Output: 0
   * ```
   */
  <
    Args,
    ReturnValue extends Args,
    FunctionSignature extends (arg: Args) => ReturnValue
  >(
    arg: Args,
    ...funcs: Array<FunctionSignature>
  ): ReturnValue;
}

/**
 * `pipe` takes multiple functions and passes the return value of one function and passes
 * it as the argument(s) to the next function. It will return the result of the last
 * function call. This is useful for calling a series of functions as "steps". The only
 * caveat to using this is that the arguments and return value of the function must be of
 * the same type. For instance, if a function accepts a number as it's only argument, it
 * must return a number. If the function accepts two strings as arguments, the function
 * must return an array of two strings.
 * @param args - Initial arguments to pass to the first function call.
 * @param funcs - A list of functions to call in order, with each function passing its
 * return value into the next function's arguments.
 * @returns The result of the last function call.
 * @example ```ts
 * pipe(1, addOne, minusTwo); // Output: 0
 * pipe([req, resp], createSession, createCSRFToken); // Output [req, resp]
 * ```
 */
export const pipe: IPipeFunction = <
  Args extends Array<unknown>,
  ReturnValue extends Args | Args[0],
  FunctionSignature extends Args extends Array<unknown>
    ? (...args: Args) => Args
    : (arg: Args) => ReturnValue
>(
  args: Args,
  ...funcs: Array<FunctionSignature>
): ReturnValue => {
  let lastReturnValue: ReturnValue = args as ReturnValue;
  for (const func of funcs) {
    if (Array.isArray(lastReturnValue)) {
      lastReturnValue = func(...lastReturnValue) as ReturnValue;
    } else {
      lastReturnValue = func(lastReturnValue) as ReturnValue;
    }
  }

  return lastReturnValue;
};

/** Represents the possible overloads of {@link pipe}. */
interface IAsyncPipeFunction {
  /**
   * `asyncPipe` takes multiple async functions and passes the return value of one async function and passes
   * it as the argument(s) to the next async function. It will return the result of the last
   * async function call. This is useful for calling a series of async functions as "steps". The only
   * caveat to using this is that the arguments and return value of the function must be of
   * the same type. For instance, if a function accepts a number as it's only argument, it
   * must return a number.
   * @param Args - A type representing the types of arguments the functions accept.
   * @param ReturnValue - A type representing the return values that the functions return.
   * @param FunctionSignature - A type representing the signature of functions in the pipeline.
   * @param args - Initial arguments to pass to the first function call.
   * @param funcs - A list of functions to call in order, with each function passing its
   * return value into the next function's arguments.
   * @returns The result of the last function call.
   * @example ```ts
   * pipe([req, resp], createSession, createCSRFToken); // Output [req, resp]
   * ```
   */
  <
    Args extends Array<unknown>,
    ReturnValue extends Args,
    FunctionSignature extends (...args: Args) => Promise<Args>
  >(
    args: [...Args],
    ...funcs: Array<FunctionSignature>
  ): Promise<ReturnValue>;

  /**
   * `asyncPipe` takes multiple async functions and passes the return value of one async function and passes
   * it as the argument(s) to the next async function. It will return the result of the last
   * async function call. This is useful for calling a series of async functions as "steps". The only
   * caveat to using this is that the arguments and return value of the function must be of
   * the same type. For instance, if the async function accepts two strings as arguments, the function
   * must return an array of two strings.
   * @param Args - A type representing the type of the argument that the functions accept.
   * @param ReturnValue - A type representing the return values that the functions return.
   * @param FunctionSignature - A type representing the signature of functions in the pipeline.
   * @param arg - The initial argument to pass to the first function call.
   * @param funcs - A list of functions to call in order, with each function passing its
   * return value into the next function's arguments.
   * @returns The result of the last function call.
   * @example ```ts
   * await asyncPipe(1, addOne, minusTwo); // Output: 0
   * ```
   */
  <
    Args,
    ReturnValue extends Args,
    FunctionSignature extends (arg: Args) => Promise<ReturnValue>
  >(
    arg: Args,
    ...funcs: Array<FunctionSignature>
  ): Promise<ReturnValue>;
}

/**
 * `asyncPipe` takes multiple async functions and passes the return value of one async function and passes
 * it as the argument(s) to the next function. It will return the result of the last
 * async function call. This is useful for calling a series of async functions as "steps". The only
 * caveat to using this is that the arguments and return value of the function must be of
 * the same type. For instance, if a function accepts a number as it's only argument, it
 * must return a number. If the function accepts two strings as arguments, the function
 * must return an array of two strings.
 * @param args - Initial arguments to pass to the first function call.
 * @param funcs - A list of functions to call in order, with each function passing its
 * return value into the next function's arguments.
 * @returns The result of the last function call.
 * @example ```ts
 * await asyncPipe(1, addOne, minusTwo); // Output: 0
 * await asyncPipe([req, resp], createSession, createCSRFToken); // Output [req, resp]
 * ```
 */
export const asyncPipe: IAsyncPipeFunction = async <
  Args extends Array<unknown>,
  ReturnValue extends Args | Args[0],
  FunctionSignature extends Args extends Array<unknown>
    ? (...args: Args) => Promise<Args>
    : (arg: Args) => Promise<ReturnValue>
>(
  args: Args,
  ...funcs: Array<FunctionSignature>
): Promise<ReturnValue> => {
  let lastReturnValue: ReturnValue = args as ReturnValue;

  for (const func of funcs) {
    if (Array.isArray(lastReturnValue)) {
      // eslint-disable-next-line no-await-in-loop -- we want to await before move to the next function
      lastReturnValue = (await func(...lastReturnValue)) as ReturnValue;
    } else {
      // eslint-disable-next-line no-await-in-loop -- we want to await before move to the next function
      lastReturnValue = (await func(lastReturnValue)) as ReturnValue;
    }
  }

  return Promise.resolve(lastReturnValue);
};

/**
 * Type checks that a switch statement is exhaustive, and immediately throws an error if it is ever reached.
 * @param _value - The value checked in the switch statement.
 * @param [errorMsg] - An optional error to throw if the switch statement is not exhaustive.
 * @throws An {@link UnreachableError} or custom error when the switch statement is not exhaustive.
 * @example
 * switch (someValue) {
 *  case 'a':
 *    return 1;
 *  case 'b':
 *    return 2;
 *  default:
 *    return exhaustiveGuard(someValue); // Throws an error.
 * }
 *
 * @see {@link https://www.meticulous.ai/blog/safer-exhaustive-switch-statements-in-typescript Safer Exhaustive Switch Statements in TypeScript}
 */
export function exhaustiveGuard(
  _value: never,
  errorMsg?: string | Error
): never {
  if (errorMsg instanceof Error) throw errorMsg;

  throw new UnreachableError(
    errorMsg ?? `Exhaustive guard failed with value ${_value}.`
  );
}

/**
 * Type checks that a switch statement is exhaustive, and immediately invokes the fallback if it is ever reached.
 *
 * This is useful when you want both:
 * - Exhaustive type checking.
 * - Graceful fallback behavior if our types are wrong.
 *
 * @param _value - The value checked in the switch statement.
 * @param [fallback] - A function to invoke and return if the switch statement is not exhaustive.
 * The function will be passed the value checked in the switch statement.
 * By default, this function is the identity function, which returns the value passed in.
 * @returns The return value of the fallback function.
 * @example
 * switch (someValue) {
 *  case 'a':
 *    return 1;
 *  case 'b':
 *    return 2;
 *  default:
 *    return exhaustiveFallback(someValue, () => 0); // Returns 0.
 * }
 *
 * @see {@link https://www.meticulous.ai/blog/safer-exhaustive-switch-statements-in-typescript Safer Exhaustive Switch Statements in TypeScript}
 */
export function exhaustiveFallback<R>(
  _value: never,
  fallback: (_value: unknown) => R = (val) => val as R
): R {
  return fallback(_value);
}

/**
 * A function that takes a function and invokes it immediately.
 * Conceptually, this is similar to a "do-expression", i.e., it is
 * useful for executing additional logic before returning some value.
 * It can also be useful for creating a temporary scope for variable
 * isolation, or for starting an asynchronous operation whose result
 * is handled outside the context of the asynchronous function.
 *
 * Generally, immediately invoked function expressions (IIFEs) should be used sparingly,
 * as they can be difficult to read and modern JavaScript has many built-in features
 * which replace their use cases. However, occasionally they are still convenient, and
 * so this utility exists solely to improve their readability.
 *
 * @param func - A function to call immediately.
 * @returns The return value of the function.
 * @see {@link https://developer.mozilla.org/en-US/docs/Glossary/IIFE IIFE on MDN}
 * @example
 * const productImage = product.images[0] ?? iife(() => {
 *   console.warn(`Product ${product.name} has no images.`);
 *   return DEFAULT_IMAGE;
 * })
 */
export function invokeImmediately<T>(func: () => T): T {
  return func();
}

/**
 * Given a function, execute it the specified amount of times.
 *
 * @param times - The amount of times to execute the function.
 * @param func - The function to execute.
 */
export function repeat(times: number, func: () => void): void {
  for (let count = 0; count < times; count++) {
    func();
  }
}
