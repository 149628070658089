import { TimeStampable } from '@/type-utils';
import { DateTime } from 'luxon';
import { exhaustiveGuard } from './function-utils';

/** Enumerates valid units of time. */
export enum TimeScale {
  Milliseconds,
  Seconds,
  Minutes,
  Hours,
  Days,
  Weeks,
  Months,
  Years
}

/**
 * Gets a date object representing when a resource should be considered expired and invalid.
 *
 * @param ttl - The number of units of time the resource is allowed to be considered valid.
 * @param [timeScale] - The unit of time to be used for `ttl`. Defaults to {@link TimeScale.Milliseconds}.
 * @param [fromDate] - An optional date to which the TTL will be applies from. Defaults to now.
 *
 * @returns A date object representing when a resource should be considered expired and invalid,
 * or Infinity if the resource should never expire.
 *
 * @throws An exception when an unknown `TimeScale` value is used at runtime.
 */
export const dateFromTTL = (
  ttl: number,
  timeScale: TimeScale = TimeScale.Milliseconds,
  fromDate: Date | undefined = undefined
): Date | typeof Infinity => {
  if (ttl === Infinity) return Infinity;

  let now = fromDate ? DateTime.fromJSDate(fromDate) : DateTime.now();

  switch (timeScale) {
    case TimeScale.Milliseconds:
      now = now.plus({ milliseconds: ttl });
      break;
    case TimeScale.Seconds:
      now = now.plus({ seconds: ttl });
      break;
    case TimeScale.Minutes:
      now = now.plus({ minutes: ttl });
      break;
    case TimeScale.Hours:
      now = now.plus({ hours: ttl });
      break;
    case TimeScale.Days:
      now = now.plus({ days: ttl });
      break;
    case TimeScale.Weeks:
      now = now.plus({ weeks: ttl });
      break;
    case TimeScale.Months:
      now = now.plus({ months: ttl });
      break;
    case TimeScale.Years:
      now = now.plus({ years: ttl });
      break;

    default:
      exhaustiveGuard(
        timeScale,
        `Unknown Timescale value "${timeScale}" passed to \`dateFromTTL\``
      );
  }

  return now.toJSDate();
};

/**
 * Given a {@link Timestampable} value, collapse it to a `Date`.
 * @param timeStampable - Any {@link Timestampable} value.
 * @returns An equivalent `Date`.
 */
export const timeStampableToDate = (timeStampable: TimeStampable): Date => {
  if (timeStampable instanceof Date) {
    return timeStampable;
  }

  return new Date(timeStampable);
};

/**
 * Given a {@link Timestampable} value, collapse it to an ISO string.
 * @param timeStampable - Any {@link Timestampable} value.
 * @returns An equivalent ISO string representing the datetime in question.
 */
export const timeStampableToISOString = (
  timeStampable: TimeStampable
): string => {
  if (timeStampable instanceof Date) {
    return timeStampable.toISOString();
  }

  return new Date(timeStampable).toISOString();
};

/**
 * A helper class for working with time and discrete time units thereof.
 *
 * NOTE: Despite accepting any timestamable value as input, this class is not intended
 * to be used for exact date arithmetic -- as it does not account for the varying number of
 * days in a month/year or for changes in daylight savings time.
 *
 * Examples of where this class might not be appropriate include:
 * - Adding 1 Time.Month to January 31st will result in March 3rd.
 * - Adding 1 Time.Year to February 29th will result in February 28th.
 * - Adding 12 Time.Month (360 days) to January 1st will result in December 27th.
 * - Adding 2 Time.Hour to 1:30am on the day of daylight savings time will result in 2:30am.
 *
 * Typical use cases might include:
 * - Setting timeouts or intervals.
 * - Setting expiration times for cache entries or cookies.
 * - Setting durations for animations or transitions.
 *
 * Basically, if the exact date and time are not important, and you simply wish to
 * add a discrete amount of time (30 days, regardless of month) or convert one discrete amount of time
 * to another, this class is a good fit.
 *
 * @example
 * ```typescript
 *
 * const second = new Time(Time.Second);
 * console.log(second.toMilliseconds()); // 1000
 *
 * const minute = new Time(Time.Minute);
 * console.log(minute.toSeconds()); // 60
 *
 * const minuteAndAHalf = minute.add(30, TimeScale.Seconds);
 * console.log(minuteAndAHalf.toSeconds()); // 90
 * console.log(minuteAndAHalf.toMinutes()); // 1
 * ```
 *
 *
 */
export class Time {
  private milliseconds: number;

  /**
   * The number of milliseconds in a year.
   *
   * NOTE: This does not account for leap years.
   *
   * @returns The number of milliseconds in a year.
   */
  public static readonly Year = 31536000000;

  /**
   * The number of milliseconds in a month.
   *
   * NOTE: This does not account for the varying number of days in a month.
   *
   * @returns A number of milliseconds in a month (30 days).
   */
  public static readonly Month = 2592000000;

  /**
   * The number of milliseconds in a week.
   * @returns The number of milliseconds in a week.
   */
  public static readonly Week = 604800000;

  /**
   * The number of milliseconds in a day.
   * @returns The number of milliseconds in a day.
   */
  public static readonly Day = 86400000;

  /**
   * The number of milliseconds in an hour.
   * @returns The number of milliseconds in an hour.
   */
  public static readonly Hour = 3600000;

  /**
   * The number of milliseconds in a minute.
   * @returns The number of milliseconds in a minute.
   */
  public static readonly Minute = 60000;

  /**
   * The number of milliseconds in a second.
   * @returns The number of milliseconds in a second.
   */
  public static readonly Second = 1000;

  /**
   * Constructs a new `Time` object.
   * @param epoch - The number of milliseconds to represent.
   */
  public constructor(epoch: TimeStampable | number) {
    switch (typeof epoch) {
      case 'number':
        this.milliseconds = epoch;
        break;
      case 'string':
      case 'object':
        this.milliseconds = timeStampableToDate(epoch).getTime();
        break;
      default:
        throw new Error(
          `unable to convert type to valid Time: ${typeof epoch}`
        );
    }
  }

  /**
   * Converts the `Time` object to a number of milliseconds.
   * @returns The number of milliseconds represented by the `Time` object.
   */
  public toMilliseconds(): number {
    return this.milliseconds;
  }

  /**
   * Converts the `Time` object to a number of seconds.
   * @returns The number of seconds represented by the `Time` object.
   *
   * @example
   * ```typescript
   * const minute = Time.Minute;
   * console.log(minute.toSeconds()); // 60
   * ```
   */
  public toSeconds(): number {
    return Math.floor(this.milliseconds / Time.Second);
  }

  /**
   * Converts the `Time` object to a number of minutes.
   * @returns The number of minutes represented by the `Time` object.
   *
   * @example
   * ```
   * const hour = Time.Hour;
   * console.log(day.toMinutes()); // 60
   * ```
   */
  public toMinutes(): number {
    return Math.floor(this.milliseconds / Time.Minute);
  }

  /**
   * Converts the `Time` object to a number of hours.
   * @returns The number of hours represented by the `Time` object.
   *
   * @example
   * ```typescript
   * const day = Time.Day;
   * console.log(day.toHours()); // 24
   * ```
   */
  public toHours(): number {
    return Math.floor(this.milliseconds / Time.Hour);
  }

  /**
   * Converts the `Time` object to a number of days.
   * @returns The number of days represented by the `Time` object.
   *
   * @example
   * ```typescript
   * const week = Time.Week;
   * console.log(week.toDays()); // 7
   * ```
   */
  public toDays(): number {
    return Math.floor(this.milliseconds / Time.Day);
  }

  /**
   * Converts the `Time` object to a number of weeks.
   * @returns The number of weeks represented by the `Time` object.
   *
   * @example
   * ```typescript
   * const month = Time.Month;
   * console.log(month.toWeeks()); // 4
   * ```
   */
  public toWeeks(): number {
    return Math.floor(this.milliseconds / Time.Week);
  }

  /**
   * Converts the `Time` object to a number of months. This does not account
   * for the varying number of days in a month. If you need that, you should
   * use the `DateTime` class from `luxon`.
   * @returns The number of months represented by the `Time` object.
   */
  public toMonths(): number {
    return Math.floor(this.milliseconds / Time.Month);
  }

  /**
   * Converts the `Time` object to a number of years.
   * @returns The number of years represented by the `Time` object.
   */
  public toYears(): number {
    return Math.floor(this.milliseconds / Time.Year);
  }

  /**
   * Overload for the add method to just add another `Time` object.
   * @param value - The `Time` object to add.
   *
   * @example
   * ```typescript
   * const time1 = new Time(1000);
   * const time2 = new Time(2000);
   *
   * const sum = time1.add(time2);
   * console.log(sum.toMilliseconds()); // 3000
   * console.log(sum.toSeconds()); // 3
   */
  public add(value: Time): Time;
  /**
   * Overload for the add method to add a number of units of time.
   * @param value - The number of units of time to add.
   * @param unit - The unit of time to add. Defaults to {@link TimeScale.Milliseconds}.
   */
  public add(value: number, unit: TimeScale): Time;
  /**
   * Adds another `Time` object to this one.
   * @param value - The number of units of time to add or another `Time` object.
   * @param unit - The unit of time to add. Defaults to {@link TimeScale.Milliseconds}.
   * @returns A new `Time` object representing the sum of the two `Time` objects.
   * @throws An exception when an unknown `TimeScale` value is used at runtime.
   */
  public add(value: number | Time, unit?: TimeScale): Time {
    if (value instanceof Time) {
      return new Time(this.milliseconds + value.toMilliseconds());
    }
    switch (unit) {
      case TimeScale.Milliseconds:
        return new Time(this.milliseconds + unit);
      case TimeScale.Seconds:
        return new Time(this.milliseconds + value * Time.Second);
      case TimeScale.Minutes:
        return new Time(this.milliseconds + value * Time.Minute);
      case TimeScale.Hours:
        return new Time(this.milliseconds + value * Time.Hour);
      case TimeScale.Days:
        return new Time(this.milliseconds + value * Time.Day);
      case TimeScale.Weeks:
        return new Time(this.milliseconds + value * Time.Week);
      case TimeScale.Months:
        return new Time(this.milliseconds + value * Time.Month);
      case TimeScale.Years:
        return new Time(this.milliseconds + value * Time.Year);
      default:
        throw new Error(`Unknown TimeScale value: ${unit}`);
    }
  }

  /**
   * Overload for the subtract method to just subtract another `Time` object.
   * @param value - The `Time` object to subtract.
   *
   * @example
   * ```typescript
   * const time1 = new Time(3000);
   * const time2 = new Time(2000);
   *
   * const difference = time1.subtract(time2);
   * console.log(difference.toMilliseconds()); // 1000
   */
  public subtract(value: Time): Time;
  /**
   * Overload for the subtract method to subtract a number of units of time.
   * @param value - The number of units of time to subtract.
   * @param unit - The unit of time to subtract. Defaults to {@link TimeScale.Milliseconds}.
   */
  public subtract(value: number, unit: TimeScale): Time;

  /**
   * Subtracts another `Time` object from this one.
   * @param value - The number of units of time to subtract or another `Time` object.
   * @param unit - The unit of time to subtract. Defaults to {@link TimeScale.Milliseconds}.
   * @returns A new `Time` object representing the difference of the two `Time` objects.
   * @throws An exception when an unknown `TimeScale` value is used at runtime.
   */
  public subtract(value: number | Time, unit?: TimeScale): Time {
    if (value instanceof Time) {
      return new Time(this.milliseconds - value.toMilliseconds());
    }
    switch (unit) {
      case TimeScale.Milliseconds:
        return new Time(this.milliseconds - value);
      case TimeScale.Seconds:
        return new Time(this.milliseconds - value * Time.Second);
      case TimeScale.Minutes:
        return new Time(this.milliseconds - value * Time.Minute);
      case TimeScale.Hours:
        return new Time(this.milliseconds - value * Time.Hour);
      case TimeScale.Days:
        return new Time(this.milliseconds - value * Time.Day);
      case TimeScale.Weeks:
        return new Time(this.milliseconds - value * Time.Week);
      case TimeScale.Months:
        return new Time(this.milliseconds - value * Time.Month);
      case TimeScale.Years:
        return new Time(this.milliseconds - value * Time.Year);
      default:
        throw new Error(`Unknown TimeScale value: ${unit}`);
    }
  }
}
