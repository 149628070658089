import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { InvalidStateError } = errorFactory('InvalidStateError', {
  mapsToHTTPError: 'ConflictError'
} as IHTTPErrorMetaData);

/** Generic Error to use when an object is determined to be in an unexpected state. */
export type InvalidStateError = InstanceTypeOf<typeof InvalidStateError>;
