import { errorFactory } from '@/utils/error-utils';
import { InvalidStateError } from '@/utils/errors';

/**
 * An error thrown for when a request is required but not available at all.
 */
export const { NoRequestAvailableError } = errorFactory(
  'NoRequestAvailableError',
  InvalidStateError
);
