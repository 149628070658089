import { Constructor } from '@/type-utils';

/**
 * Determines if a function or class is constructable at runtime.
 * @param fn - A function or class definition that we want to check whether it
 * is constructable at runtime.
 * @returns `true` if the argument passed can be instantiated with the `new`
 * keyword.
 */
export const isConstructable = (
  // eslint-disable-next-line @typescript-eslint/ban-types -- `Function` is needed because this can truly be any function.
  fn: Function | Constructor<unknown>
): boolean => {
  try {
    // @ts-expect-error -- It's unsure if `fn` is constructable, but that's
    // what we're checking anyway, so...
    // eslint-disable-next-line no-new -- Needed to test whether a class is constructable.
    new new Proxy(fn, { construct: () => ({}) })();
    return true;
  } catch (err) {
    return false;
  }
};
