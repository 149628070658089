import { errorFactory } from '@/utils/error-utils';
import { InvalidStateError } from '@/utils/errors';

/**
 * An explicit error that happens when you attempt to get a current request outside of a request.
 * Usually this means the route is not wrapped in a middleware.
 */
export const { NoRequestIDError } = errorFactory(
  'NoRequestIDError',
  InvalidStateError
);
