import { Site } from '@/constructs/Site';
import { Brand } from '@/constructs/Brand';

/** Represents a mapping of sites to their respective brands. */
export const SiteBrandMap = new Map<Site, Brand>([
  [Site.UGG, Brand.UGG],
  [Site.UGGCOLLAB, Brand.UGG],
  [Site.Teva, Brand.Teva],
  [Site.Ahnu, Brand.Ahnu],
  [Site.Hoka, Brand.Hoka],
  [Site.Sanuk, Brand.Sanuk],
  [Site.Conductor, Brand.Conductor],
  [Site.Koolaburra, Brand.Koolaburra]
]);
