import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '../error-utils';

export const { EnvironmentError } = errorFactory('EnvironmentError');

/**
 * Error to use to indicate that code was executed in or configured for an
 * incorrect environment.
 */
export type EnvironmentError = InstanceTypeOf<typeof EnvironmentError>;
