import type { Nullable } from '@/type-utils';

/**
 * Checks if a value is `null`, `undefined`, or `''` (an empty string).
 * @param value - The value to check.
 * @returns `true` if the value is `null`, `undefined`, or `''`.
 */
export function isNullOrEmpty(value: unknown): value is null | undefined | '' {
  return value == null || value === '';
}

/**
 * Checks if a value is `null`, `undefined`, or `0` (zero).
 * @param value - The value to check.
 * @returns `true` if the value is `null`, `undefined`, or `0`.
 */
export function isNullOrZero(value: unknown): value is null | undefined | 0 {
  return value == null || value === 0;
}

/**
 * Checks if a value is `null` or `undefined`.
 * @param value - The value to check.
 * @returns `true` if the value is `null` or `undefined`.
 */
export function isNullish(value: unknown): value is null | undefined {
  return value == null;
}

/**
 * Does nothing but cast a value to a {@link Nullable} type.
 *
 * This is a convenience function for explicitly marking a value as `Nullable`,
 * which can be useful while `noUncheckedIndexedAccess` is disabled in our
 * TypeScript configuration, or for marking a value as susceptible to being
 * bad data.
 *
 * @param value - The value to convert to a `Nullable` type.
 * @returns The value as a `Nullable` type.
 * @see {@link https://www.typescriptlang.org/tsconfig/#noUncheckedIndexedAccess noUncheckedIndexedAccess}
 * @example
 * const product = maybe(productRecord[productKey]);
 * if (!product) throw new Error(`Product with key "${productKey}" not found.`);
 */
export const maybe = <const T>(value: T): Nullable<T> => value;

/**
 * **DO NOT USE**.
 * @deprecated This function exists for the sole purpose of preventing someone else
 * from creating it. The problem with this function is that the type predicate is
 * incomplete and does not cover all possible falsy values. Specifically, `NaN` is
 * a falsy value, but TypeScript does not have a literal `NaN` type.
 *
 * As a result, using this function can lead to unexpected behavior. For instance,
 * imagine a variable `num` of type `number` that is assigned `NaN`. Then `isFalsy(num)`
 * would narrow the type of `num` to `0` in the `true` branch, which is incorrect.
 *
 * Instead, use the `Boolean` function or `!!` to test truthiness, or use the more
 * granular falsy type guards like {@link isNullOrEmpty}, {@link isNullOrZero}, or {@link isNullish}.
 * @param value - The value to check.
 * @returns `true` if the value is falsy, `false` otherwise.
 */
export function isFalsy(
  value: unknown
): value is false | null | undefined | 0 | 0n | '' {
  return !Boolean(value);
}
